import { GetStaticPaths, GetStaticProps } from 'next';
import { logger } from '@/api/lib';
import { TemplatePage } from '@/pages/template';
import { profileServerModel } from '@/entities/Profile';
import {
  checkRequestErrors,
  getGoogleFeedbacks,
  getServerCLient,
  getYelpFeedbacks,
} from '@/sharedAPI';
import { deviceType, getMenu, parseSSGParams, serialize, timeInSeconds } from '@/sharedLib';

const LOG_NAME = 'Main Page';
const DEFAULT_SUBDOMAIN = 'mark';

export const getStaticProps: GetStaticProps = async (context) => {
  const { device } = parseSSGParams(context.params);
  const { ssrCache, client } = getServerCLient();

  const isValidPath = Object.values(deviceType).includes(device);
  if (!isValidPath) {
    return {
      notFound: true,
    };
  }
  const { profileData, config, error, services, feedbacks } = await profileServerModel.getUserData(
    DEFAULT_SUBDOMAIN,
    client,
  );
  if (error?.graphQLErrors?.[0]?.extensions?.code === 404) {
    return {
      notFound: true,
    };
  }

  const { urqlState, revalidate } = checkRequestErrors(ssrCache.extractData(), timeInSeconds.WEEK);

  if (error) {
    logger.error({ name: LOG_NAME, error }, 'userData error');
    return {
      props: {
        urqlState,
      },
      revalidate: timeInSeconds.ONE_SEC,
    };
  }

  const menu = getMenu({
    feedbacks,
    services,
    projectConfig: config,
  });

  const [googleFeedbacks, yelpFeedbacks] = await Promise.all([
    getGoogleFeedbacks(config?.placeId || profileData?.googlePlace?.id, profileData?.id),
    getYelpFeedbacks(config?.yelpId!, profileData?.id),
  ]);

  const props = serialize({
    urqlState,
    config,
    subdomain: DEFAULT_SUBDOMAIN,
    reviews: {
      google: googleFeedbacks,
      yelp: yelpFeedbacks,
    },
    profileData,
    menuLinks: menu,
  });

  return {
    props,
    revalidate,
  };
};

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [],
    fallback: 'blocking',
  };
};

export default TemplatePage;
