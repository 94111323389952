import { FC } from 'react';
import { InfoBlock } from '@/widgets/Info';
import { MainBlock } from '@/widgets/MainBlock';
import { OurWorkers } from '@/widgets/OurWorkers';
import { PhotoPortfolio } from '@/widgets/PhotoPortfolioBlock';
import { PortfolioBlock } from '@/widgets/PortfolioBlock';
import { ReviewsBlock } from '@/widgets/ReviewsBlock';
import { ServicesBlock } from '@/widgets/ServicesBlock';
import { ContactUsForm } from '@/widgets/contactUsForm';
import { ContactsBlock } from '@/widgets/contactsBlock';
import { Map } from '@/widgets/map';
import { TBlockKey, TBlockProps, TBlockType } from '@/sharedLib';

export type TListBlockItem = {
  key: TBlockKey;
  type: TBlockType;
  previewImage: string;
  description: string;
  index?: number;
};

export type TBlockComponents = FC<TBlockProps> | null;

export const mapKeyToBlockComponents: Record<TBlockKey, TBlockComponents> = {
  main: MainBlock,
  info: InfoBlock,
  services: ServicesBlock,
  portfolio: PortfolioBlock,
  images: PhotoPortfolio,
  reviews: ReviewsBlock,
  map: Map,
  contacts: ContactsBlock,
  workers: OurWorkers,
  footer: null,
  header: null,
  generalSettings: null,
  contactUsForm: ContactUsForm,
};
