import { FC } from 'react';
import { Footer } from '@/widgets/footer';
import { Header } from '@/widgets/header';
import { blocksListSelector, configModel } from '@/entities/Config';
import { Layout } from '@/sharedUI';
import { MetaDecorator } from '@/sharedLib';
import { mapKeyToBlockComponents } from '../lib/templateMaps';
import EmptyTemplate from './EmptyTemplate';

const Template: FC = () => {
  const blocksList = configModel.useStore(blocksListSelector);

  return (
    <Layout.Page
      key='pageLayout'
      footer={<Footer index={null} />}
      header={<Header index={null} />}
    >
      <MetaDecorator />

      {!!blocksList?.length ? (
        blocksList?.map(({ key, index }, position: number) => {
          const Block = mapKeyToBlockComponents[key];

          const isOdd = position % 2 === 1;

          if (!Block) return null;

          return (
            <Block
              key={key + position}
              index={index ?? null}
              isOdd={isOdd}
            />
          );
        })
      ) : (
        <EmptyTemplate />
      )}
    </Layout.Page>
  );
};

export default Template;
