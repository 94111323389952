import { Heading } from '@chakra-ui/react';
import { configModel, settingsSelector } from '@/entities/Config';
import { Layout } from '@/shared/ui';

const EmptyTemplate = () => {
  const { companyName } = configModel.useStore(settingsSelector);

  return (
    <Layout.Container
      bgGradient='linear(to-tl, primary.dark, primary.medium)'
      h='100dvh'
      position='absolute'
      top='0'
      zIndex='9999'
    >
      <Heading
        color='white'
        size='2xl'
        textAlign='center'
      >
        {companyName || 'LocalProf pages'}
      </Heading>
    </Layout.Container>
  );
};

export default EmptyTemplate;
